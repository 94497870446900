<template>
    <div class="xl:container my-5 lg:px-5 sm:px-2 mx-auto overflow-x-scroll">
        <div class="flex mb-5 ">
            <button @click="tab = idx" v-for="(item,idx) in tabs" :key="'tab'+idx" class="px-6 py-3 text-white mr-2"
                    :class="{'bg-green-400 border-green-400':selected.name === item.name}"

            >{{ item.name }}
            </button>
        </div>

        <component :is="selected.component"></component>

    </div>
</template>

<script>



export default {
    meta: {
        title: 'Админиcтрирование'
    },
    name: "Admin",

    data: () => ({
        tab:0,
        tabs:[
            {
                name:"Все",
                component:() => import('@/components/user/admin/ContractsAll')
            },
            {
                name:"Валютные",
                component:() => import('@/components/user/admin/ContractsExceeded')
            },
            {
                name:"Заявки",
                component:() => import('@/components/user/admin/ContractsRequests')
            }
        ],


    }),
    computed:{
        selected(){
            return this.tabs[this.tab]
        }
    }


}
</script>

<style scoped>
::-webkit-scrollbar {
    display: none;
}
</style>